"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var errors_1 = require("../../form/errors");
var DeclarationRow_1 = require("./data/DeclarationRow");
exports.default = {
    props: {
        row: DeclarationRow_1.DeclarationRow,
        errors: errors_1.Errors,
        index: Number,
    },
    data: function () {
        return {};
    },
    methods: {}
};
