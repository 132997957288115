"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    props: {
        type: String,
        showTime: Number
    },
    mounted: function () {
        $(this.$el).delay(this.showTime).slideUp(300);
    }
};
