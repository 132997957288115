"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Errors = void 0;
var Errors = /** @class */ (function () {
    function Errors() {
        this.data = {};
    }
    Errors.prototype.get = function (fieldName) {
        return this.data[fieldName];
    };
    Errors.prototype.has = function (fieldName) {
        return this.data[fieldName] && this.data[fieldName].length > 0;
    };
    Errors.prototype.clear = function (fieldName) {
        delete this.data[fieldName];
    };
    Errors.prototype.load = function (data) {
        this.data = data;
    };
    return Errors;
}());
exports.Errors = Errors;
