"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    props: {
        label: String,
        numberFull: Number,
        numberPartial: Number,
        targetNumber: Number,
    },
    computed: {
        percentageFull: function () {
            return Math.min(this.numberFull / this.targetNumber, 1) * 100;
        },
        percentagePartial: function () {
            return Math.min(this.numberPartial / this.targetNumber, 1 - (this.percentageFull / 100)) * 100;
        },
        styleBarFull: function () {
            return "width: ".concat(this.percentageFull, "%");
        },
        styleBarPartial: function () {
            return "width: ".concat(this.percentagePartial, "%");
        }
    }
};
