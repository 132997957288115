"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    props: {
        listing: {
            type: Boolean,
            default: true,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        mimetypes: Array,
    },
    data: function () {
        return {
            isHovering: false,
            files: [],
        };
    },
    computed: {
        hasFiles: function () {
            return this.files.length > 0;
        },
        fileCount: function () {
            return this.files.length;
        },
    },
    methods: {
        filesChanged: function () {
            var input = this.$refs.input;
            if (!input.files) {
                return;
            }
            var allowedFiles = this.filterFileList(input.files);
            if (allowedFiles.length) {
                this.addFiles(allowedFiles);
            }
            input.value = "";
        },
        dragEnter: function (evt) {
            this.isHovering = true;
        },
        fileDrop: function (evt) {
            if (!evt.dataTransfer) {
                return;
            }
            var allowedFiles = this.filterFileList(evt.dataTransfer.files);
            if (allowedFiles.length) {
                this.addFiles(allowedFiles);
            }
            this.isHovering = false;
        },
        dragLeave: function () {
            this.isHovering = false;
        },
        filterFileList: function (files) {
            var mimeTypes = this.mimetypes;
            var allFiles = Array.from(files);
            var illegalFiles = allFiles.filter(function (f) { return !mimeTypes.includes(f.type); });
            if (illegalFiles.length) {
                this.$emit('files-not-allowed', illegalFiles);
            }
            return allFiles.filter(function (f) { return mimeTypes.includes(f.type); });
        },
        addFiles: function (files) {
            var _a;
            (_a = this.files).push.apply(_a, files);
            this.$emit('files-uploaded', files);
        }
    },
};
