"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    props: {
        roles: Array,
        capabilities: Array,
        capabilitiesPerRole: Object,
    },
    data: function () {
        return {
            filter: "",
        };
    },
    computed: {
        filteredCapabilities: function () {
            var filterValue = this.filter.toLowerCase();
            if (!filterValue) {
                return this.capabilities;
            }
            return this.capabilities.filter(function (c) { return c.description.toLowerCase().includes(filterValue); });
        }
    }
};
